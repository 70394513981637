<template>
  <div
    :class="'recharge-page ' + LANGCODE"
    :style="'--aog-currency:\'' + SYSLANG.currency + '\''"
  >
    <div class="header">
      <img class="member-bg" :src="require('@/assets/images/bg-index.png')" />
      <div class="credit">
        <span class="title">线下沙龙</span>
      <!--  <span class="price">{{ agentInfo.Balance }}</span> -->
      </div>
    </div>

    <div class="credit-block">
      <van-form @submit="onSubmit">
       
        <van-field
          name="TradeNo"
          v-model="TradeNo"
          label="沙龍文字" 
		  minlength="150"
		  rows="10"
          placeholder="請輸入文字需150字以上"
          label-align="top"
		  type="textarea"
		  @input="onInput"
        />
        <div class="van-cell van-field">
          <div class="van-cell__title van-field__label van-field__label--top">
             線下沙龍圖片(最少上傳四張)
          </div>
          <van-uploader
            v-model="TradeProof"
            multiple
            :max-count="9"
            :upload-text="PAGELANG.tradeprooftext"
            :before-read="beforeRead"
            :after-read="uploadTradeProof"
            class="trade-proof"
          />
          <div
            v-if="showTradeProofMsg"
            class="van-field__error-message"
            style="width: 100%"
          >
            {{ PAGELANG.relestradeproof }}
          </div>
        </div>
        <van-button
          type="primary"
          block
          color="var(--van-card-price-color)"
          native-type="submit"
          class="btn-submit"
          >提交</van-button
        >
      </van-form>
    </div>
  </div>


</template>

<script>
import { showLoadingToast, showToast, closeToast } from "vant";
import { doResizeImage, getCookie } from "../util/index.js";


export default {

  data() {
    return {
      agentInfo: {},
      TradeNo: "",
      TradeProof: [],
      showTradeProofMsg: false,
      useraccount: [],
      chargelimit: 0,
      recharge: {},
      PAGELANG: {},
      rules: {},
      labelWidth: "120px",
	  ticketUrls:[],
	  transferUserName:"",
	  textLength: 0,//文字字數
	  goodsTutorId:"",// 导师任务id
	  update:false,//是否被駁回
	  
    };
  },
  beforeCreate() {
    if (!getCookie("agentlogintoken")) {
      this.$router.replace({
        path: "/login",
      });
      return;
    }
  },
  created() {
    if (!getCookie("agentlogintoken")) {
      return;
    }
	
	this.axios.get(this.actions.memberInfo).then((response) => {
		  this.transferUserName=response.data.data.realName
	})
	
	this.axios.get(this.actions.teacherSchedule).then((response) => {
		  this.goodsTutorId=response.data.data.goodsTutorId
		  if(response.data.data.salon.introduce.length>0 && response.data.data.salon.state==2 ){
			  this.TradeNo=response.data.data.salon.introduce
			  this.update=true
			  this.id=response.data.data.salon.id
		  }
	})


	

    this.PAGELANG = this.LANG.pages.recharge;
    this.SYSLANG = this.LANG.system;
    this.LANGCODE = this.langcode;

    if (this.LANGCODE != "zh") {
      this.labelWidth = "190px";
    }

    this.rules = {
    
      TradeNo: [{ required: true, message: this.PAGELANG.rulestradeno }],
    };

    this.getMemberInfo();
  },
  methods: {
	  
	//添加文字
	onInput(){
		this.textLength = this.TradeNo.length;
	},
    getMemberInfo() {
      showLoadingToast({
        message: this.SYSLANG.loading,
        duration: 0,
        forbidClick: true,
      });
      this.chargelimit = 46000;
	  closeToast(); 
		   
	  this.axios.get(this.actions.offlineDesc).then(res=>{
		  console.log('asd',res)
		  let arr=[]
		  arr.push(res.data.data.accounts[0])
		  this.useraccount=arr
	  })
	  
    },
    formatMoney(money) {
      if (!money) return "0.00";
      let integer = Math.floor(money).toString();
      let thousands = integer
        .split("")
        .reverse()
        .join("")
        .replace(/(\d{3})/g, "$1.")
        .replace(/,$/, "");

      if (thousands.substr(-1, 1) == ".") {
        thousands = thousands.substring(0, thousands.length - 1);
      }

      return thousands.split("").reverse().join("");
    },
    formatter(val) {
      return val.replace(/[^\d]*/g, "").replace(/(?=(\B)(\d{3})+$)/g, ".");
    },
    beforeRead(file) {
      if (
        file.type !== "image/jpeg" &&
        file.type !== "image/png" &&
        file.type !== "image/gif"
      ) {
        showToast(this.PAGELANG.uploadfile);
        return false;
      }
      return true;
    },
    uploadTradeProof(file) {
      this.showTradeProofMsg = false;
      console.log(file);
	  const forms = new FormData()
	  forms.append('file', file.file)
	  this.axios.post(this.actions.upload,forms).then(res=>{
		  console.log(res)
		  this.ticketUrls.push(res.data.data.url)
	  })
	  
    },
    checkAmount(val) {
      console.log(val);
	  
	  if(this.textLength<150 ){
	     return('需要大於150字')
	  }
    },

    onSubmit() {
      if (this.TradeProof.length <= 0) {
        this.showTradeProofMsg = true;
        return false;
      }
	  // if(this.textLength<150 ){
		 // showToast('需要大於150字')
	  //    return;
	  // }
	  if (this.ticketUrls.length <4) {
	    showToast('上傳圖片最少四張')
	    return;
	  }
      showLoadingToast({
        message: this.SYSLANG.正在提交申请,
        forbidClick: true,
        duration: 0,
      });

      this.issubmit = true;
      this.showTradeProofMsg = false;
      let TradeProof = [];
      for (let i = 0; i < this.TradeProof.length; i++) {
          doResizeImage(this.TradeProof[i].content, 1000, 1000).then((res) => {
          this.TradeProof[i].content = res;
          TradeProof[i] = res;
          if (i == this.TradeProof.length - 1) {
            let postdata = new Object();
            postdata.introduce = this.TradeNo;
            postdata.url =this.ticketUrls;
			postdata.goodsTutorId=this.goodsTutorId
			postdata.type=1
			postdata.state=0
            console.log('postdata',postdata);
            if(this.update==true){
				postdata.id=this.id
				this.axios.post(this.actions.updateSalonCourseApproval,postdata).then(res=>{
					  console.log(res)
					  if(res.data.code==200){
						  closeToast();
						  showToast({
						    message:"提交成功,等待审核",
						    forbidClick: true,
							onClose: () => {
							  this.$router.replace({
							       path: "/teacherSchedule",
							      });
							   },
						  });
						  
					  }else{
						  closeToast();
						  showToast({message:res.data.message})
					  }
				})
			}else{
				this.axios.post(this.actions.addSalonCourseApproval,postdata).then(res=>{
					  console.log(res)
					  if(res.data.code==200){
						  closeToast();
						  showToast({
						    message:"提交成功,等待审核",
						    forbidClick: true,
							onClose: () => {
							  this.$router.replace({
							       path: "/teacherSchedule",
							      });
							   },
						  });
						  
					  }else{
						  closeToast();
						  showToast({message:res.data.message})
					  }
				})
			}
          }
        });
      }
    },
  },
};
</script>

<style src="../assets/css/recharge.css" scoped></style>
<style scoped>
.recharge-page:not(.zh) .trade-proof >>> .van-uploader__upload-text {
  line-height: 14px;
  text-align: center;
}
</style>